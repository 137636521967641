.btn-secondary { 
  background-color: var(--secondary) !important;
}

.btn-primary { 
  background-color: var(--primary) !important;
}

.bg-primary2 {
  background-color: var(--primary2) !important;
}

.bg-light {
  background-color: var(--light) !important;
}

#onetrust-pc-sdk .ot-cat-item>button:focus, #onetrust-pc-sdk .ot-acc-cntr>button:focus, #onetrust-pc-sdk li>button:focus {
  outline: none!important;
}
