.filter-select {
    width: 189px;
    background: #fff;
    color: #525f7f;
    position: relative;

    button {
        width: 189px;
        background-color: #fff !important;
        color: #525f7f;

        &:hover {
            box-shadow: none;
            -webkit-transform: none;
        }
    }

    .filter-select__label {
        text-align: left;

        &::after {
            position: absolute;
            right: 5%;
            top: 45%;
        }
    }

    .filter-select__menu {
        overflow: auto;
        overflow-x: hidden;
        max-height: 200px;
        position: absolute;
        left: 0;
        right: 0;
    }
}

.modal_calendar {
    position: absolute;
    right: 0;
    z-index: 100;
    border: 1px solid silver;
}

.form_wrapper {
    position: relative;
}

.btn-clear {
    opacity: .85;
    margin-left: 4px;
    margin-right: 4px;
}

.form-control[readonly] {
    background-color: #fff;
}